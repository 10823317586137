@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// "homepage": "https://ngf93.github.io/serve",


:root {
    --h-height: 80px;
    --f-height: 220px;
    --m-footer: 60px;
    --w: 1320px;

    @media (max-width: 1399px) {
        --w: 1140px;
    }

    @media (max-width: 1199px) {
        --f-height: 270px;
        --w: 960px;
    }

    @media (max-width: 991px) {
        --w: 720px;
        --f-height: 360px;
    }

    @media (max-width: 767px) {
        --h-height: 60px;
        --w: 540px;
        --f-height: 540px;
    }

    @media (max-width: 575px) {
        --w: 100%;
        --f-height: 480px;
    }
}

$main-font: "Noto Sans";
$color-1: #105622;
$color-2: #26C24A;
$color-3: #AAEEBA;
$color-4: #fff2ed;
$color-5: #FF6332;
$gray-1: #F2F2F2;
$gray-2: #BFBFBF;
$gray-3: #737373;
$gray-4: #404040;
$gray-5: #0D0D0D;
$blue: #299dfc;
$red: #d70000;
$transition: .3s ease;
$radius: 2em;
$radius-sm: 1em;
$mess-mine: #EAFAED;
$mess-user: #EBF3FC;
$mess-admin: #FFE9D5;
$mess-system: #F4F6F8;
$mess-system-border: #212B360D;
$mess-ban: #FF5630;

$bp-sm: 575.98px;
$bp-md: 767.98px;
$bp-lg: 991.98px;
$bp-xl: 1199.98px;
$bp-xxl: 1399.98px;

/* mixins */
@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
    display: flex;
    flex-direction: $dir;
    justify-content: $x;
    align-items: $y;
    flex-wrap: $wrap;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin no-appearance {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    outline: none;
}

@mixin safariFix {
    isolation: isolate;
    transform: translateZ(0);
}

@mixin absBG {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@mixin box {
    border-radius: $radius;
    border: 1px solid $gray-1;
    padding: 2em;
    box-shadow: 0px 2px 6px $gray-1;

    @media (max-width: 575px) {
        border-radius: $radius-sm;
        padding: 1em;
    }

    hr {
        background-color: $gray-1;
        height: 1px;
        width: 100%;
        margin: 1em 0px;
        border: none;
        opacity: 1;

        &.dark {
            background-color: transparent;
            border: 1px dashed $gray-2;
        }
    }
}


@mixin clamp($count, $line, $strict: false) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: $line;

    @if $strict {
        height: $line * $count;
    }

    @else {
        max-height: $line * $count;
    }
}

@mixin list {
    list-style: none;
    padding-left: 0px;
}

@mixin rounedImg($w) {
    width: $w;
    height: $w;
    border-radius: 50%;
    object-fit: cover;
}


a,
a:hover,
a:focus,
a:active {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
button,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
address {
    line-height: 130%;
    margin-bottom: 0px;
}

.special-label {
    display: none;
}

/* Form elements */
input,
textarea {
    background: #fff;
    border: 1px solid transparent;
    border-radius: $radius;
    padding: 1em 1.5em;
    width: 100%;
    line-height: normal;
    outline: none;
    display: block;
    transition: $transition;

    &:-moz-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: $gray-3;
        font-weight: 400;
    }

    &:focus {
        border: 1px solid $gray-2;
        box-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
        -webkit-text-fill-color: var(--font-color) !important;
    }

    &::placeholder {
        color: $gray-3;
        font-weight: 400;
    }

    @media (max-width: 575px) {
        padding: .75em 1em;
        border-radius: $radius-sm;
    }
}

input {
    &[type='radio'] {
        @include no-appearance();
        cursor: pointer;
        width: 1.25em;
        height: 1.25em;
        background-color: #fff;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        transition: $transition;
        border: 1px solid $gray-2;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 50%;
            height: 50%;
            border-radius: 50%;
            background-color: $gray-2;
            transform: translate(50%, 50%);
            transition: $transition;
        }

        &:checked {
            border: 1px solid $color-2;

            &::after {
                background-color: $color-2;
            }
        }
    }

    &[type='checkbox'] {
        @include no-appearance();
        cursor: pointer;
        width: 1.25em;
        height: 1.25em;
        background-color: #fff;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        transition: $transition;
        border: 1px solid $gray-2;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: url(imgs/icons/mark-green.svg) no-repeat center;
            background-size: 100% 100%;
            opacity: 0;
            transition: $transition;
        }

        &:checked {
            border: 1px solid $color-2;

            &::after {
                opacity: 1;
            }
        }

        &.switch {
            width: 2.5em;
            border-radius: $radius-sm;

            &::after {
                top: 10%;
                left: 5%;
                width: 40%;
                height: 80%;
                background-color: $gray-2;
                background-image: unset;
                opacity: 1;
            }

            &:checked {
                background-color: $color-2;

                &::after {
                    left: 55%;
                    background-color: #fff;
                }
            }
        }
    }
}

.notification {
    box-sizing: border-box;
    padding: 13px 20px;
    box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
    border-radius: 12px;
    color: $gray-5;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.9em;
    position: relative;
    z-index: 999999;
    margin-bottom: 15px;

    &:hover,
    &:focus {
        opacity: 1;
    }

    &-container {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 999999;
        width: 320px;
        top: 65px;
        padding: 0px 15px;
        max-height: calc(100% - 30px);
    }

    .title {
        font-size: 1em;
        line-height: 1.2em;
        font-weight: bold;
        margin: 0 0 5px 0;
    }

    &-enter {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);

        &.notification-enter-active {
            visibility: visible;
            transform: translate3d(0, 0, 0);
            transition: all 0.4s;
        }
    }

    &-exit {
        visibility: visible;
        transform: translate3d(0, 0, 0);

        &.notification-exit-active {
            visibility: hidden;
            transform: translate3d(100%, 0, 0);
            transition: all 0.4s;
        }
    }

    &-info {
        color: $blue;
    }

    &-success {
        color: $color-2;
    }

    &-warning {
        color: $color-5;
    }

    &-error {
        color: $red;
    }
}

.password {
    position: relative;

    input {
        font-size: inherit;
        font-weight: inherit;
        position: relative;
        z-index: 1;
    }

    button {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 5;
        color: $gray-3;
        height: 100%;
        width: 2em;
        font-size: 1.6em;
        @include flex();

        svg {
            path {
                stroke-width: 1.25px;
            }
        }
    }
}

fieldset {
    legend {
        display: block;
        float: unset;
    }
}

.form-control {
    line-height: 3em;
    border: transparent;
    border-radius: $radius;
    font-size: 1em;
}

label {
    display: flex;
    align-items: flex-start;

    span {
        flex: 1;
    }
}

.simple-select {
    &-container {
        width: fit-content;
        max-width: 100%;

        &.borderless {
            .simple-select__control {
                border: none !important;
            }
        }

        @media (max-width: 575px) {
            min-width: unset;
        }
    }

    &__control {
        border-radius: $radius !important;
        height: 100%;
        border: 1px solid $gray-2 !important;
        background-color: #fff !important;
        transition: $transition !important;
        padding: .7em;

        @media (max-width: 575px) {
            border-radius: $radius-sm !important;
            padding: .5em;
        }

        &--is-focused {
            border: 1px solid $color-2 !important;
            box-shadow: none !important;
        }
    }

    &__value-container {
        padding: 0px !important;
        flex-wrap: nowrap !important;
    }

    &__placeholder {
        white-space: nowrap;
    }

    &__single-value {
        color: #000 !important;
        cursor: pointer;
    }

    &__indicators {
        margin-left: .5em;
    }

    &__indicator {
        color: $gray-3 !important;
        transition: $transition !important;
        cursor: pointer;
        padding: 0px !important;
        width: 2em;
        height: 1.35em;

        &:hover {
            color: $color-2 !important;
        }

        svg {
            width: 100% !important;
            height: 100% !important;
        }

        &-separator {
            height: 80%;
            margin: 0px !important;
            align-self: unset !important;
        }
    }

    &__input-container {
        margin: 0px !important;
        padding: 4px 0px !important;
        cursor: text;
    }

    &__multi-value {
        background-color: $gray-1 !important;
        border-radius: 1em !important;

        &__remove {
            background-color: $gray-1;
            border-radius: 0px;
            color: #000;
            border-radius: 50% !important;
            transition: $transition;

            &:hover {
                background-color: $red !important;
                color: #fff !important;
            }
        }
    }

    &__menu {
        margin-top: 0px !important;
        top: calc(100% + .5em) !important;
        font-size: 0.9em;
        min-width: 100%;
        background-color: #fff !important;
        border: 1px solid $gray-2 !important;
        border-radius: $radius*.5 !important;
        box-shadow: none !important;
        outline: none !important;
        width: fit-content !important;
        z-index: 10 !important;
        overflow: hidden;
    }

    &__option {
        transition: $transition;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active,
        &--is-focused {
            background-color: $color-3 !important;
            color: #000;
            cursor: pointer;
        }

        &--is-selected {
            background-color: $color-2 !important;
            color: #000;
        }
    }
}

.input-file {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: $radius;
    transition: $transition;
    background-color: #fff;
    padding: 1em 1em 1em 2.5em;
    position: relative;

    @media (max-width: 575px) {
        border-radius: $radius-sm;
        padding: .75em 1em .75em 2em;
    }

    &:hover {
        cursor: pointer;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 1px;
        height: 1px;
        top: 0px;
        left: 0px;
        padding: 0px;
    }

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1em;
        z-index: 10;
        color: $color-2;
        height: 1.5em;
        width: 1.5em;

        @media (max-width: 575px) {
            height: 1.5em;
            width: 1.5em;
        }
    }

    .ind {
        color: $color-2;
        font-weight: 600;

        &:empty {
            display: none;
        }
    }
}

.add-photo {
    @include safariFix();
    @include flex-center();
    border: 2px dashed $gray-2;
    background-color: #fff;
    border-radius: 10px;
    font-size: 2em;
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    transition: $transition;

    @media (max-width: 767px) {
        width: 80px;
        height: 80px;
    }

    @media (max-width: 575px) {
        width: 60px;
        height: 60px;
    }

    &:hover {
        border: 2px dashed $color-2;

        svg {
            color: $color-2;
        }
    }

    &.big {
        width: 250px;
        height: 250px;
        font-size: 4em;

        @media (max-width: 767px) {
            width: 200px;
            height: 200px;
        }
    }

    svg {
        position: relative;
        z-index: 1;
        color: $gray-3;
        transition: $transition;
    }

    input {
        @include no-appearance();
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;
        padding: 0px;
    }

    img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        object-fit: contain;
    }
}

fieldset.labeled-input {
    border-radius: $radius;
    position: relative;

    legend {
        float: unset;
        width: fit-content;
        margin-left: 10px;
        color: $gray-4;
        font-size: .8em;
        padding: 0px 10px;
        margin-bottom: -0.3em;
        z-index: 10;
        position: relative;

        &:empty {
            display: none;
        }
    }

    input,
    select,
    textarea {
        border: 1px solid transparent;

        &:focus {
            border: 1px solid $gray-2;
            box-shadow: none;
        }
    }

    .error-text {
        position: absolute;
        top: 100%;
        left: 1.5em;
        color: $red;
        font-size: 0.8em;
    }
}

button {
    background: none;
    border: none;
    padding: unset;
    display: block;
    font-family: inherit;
    outline: none;
    color: inherit;
}

.btn {
    @include flex-center;
    padding: 1em 1.5em;
    text-align: center;
    transition: $transition;
    width: fit-content;
    font-size: 1em;
    font-weight: 500;
    border-radius: $radius;

    @media (max-width: 575px) {
        padding: .75em 1em;
    }

    &-none {
        padding: 0px;
        border: none;

        &:before,
        &:after {
            content: unset;
        }

        &:hover,
        &:active,
        &.active {
            border: none;
        }
    }

    &-1 {
        @extend .btn;
        color: #fff;
        background: $color-2;

        &:hover,
        &:active,
        &.active {
            color: #fff;
            background: $color-5;
        }
    }

    &-2 {
        @extend .btn;
        color: $gray-5;
        background: #fff;

        &:hover,
        &:active,
        &.active {
            color: #fff;
            background: $color-5;
        }
    }

    &-3 {
        @extend .btn;
        color: #fff;
        background: $color-5;

        &:hover,
        &:active,
        &.active {
            color: #fff;
            background: $color-2;
        }
    }

    &-4 {
        @extend .btn;
        color: $gray-5;
        background: $gray-1;

        &:hover,
        &:active,
        &.active {
            color: $gray-5;
            background: $color-3;
        }
    }

    &[disabled] {
        color: #fff;
        background: $gray-3;
    }

}


.form-search-1 {
    position: relative;

    input {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 2.4em;
        background-color: $gray-1;
    }

    button {
        position: absolute;
        z-index: 10;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 2.4em;
        color: $gray-3;
        border-radius: 50%;
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
            color: $color-1;
        }

        svg {
            font-size: 1.25em;
        }
    }
}

.form-search-2 {
    position: relative;
    max-width: 500px;
    border-radius: 2em;
    overflow: hidden;
    @include safariFix();

    input {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 3em;
    }

    button {
        position: absolute;
        z-index: 10;
        right: 0em;
        top: 0em;
        height: 3em;
        width: 3em;
        padding: 0px;
        @include flex-center();

        svg {
            font-size: 2em;
        }
    }
}

.form-respond {
    @media (max-width: 575px) {
        font-size: .9em;
    }
}

/* Text styles */
h1 {
    text-align: left;
    font-size: 4em;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 1em;

    @media (max-width: 575px) {
        font-size: 2.5em;
    }

    &.inner {
        font-size: 2em;

        @media (max-width: 575px) {
            font-size: 1.5em;
        }
    }
}

h2 {
    text-align: left;
    font-size: 2.4em;
    font-weight: 500;
    margin-bottom: .5em;

    @media (max-width: 575px) {
        font-size: 1.75em;
    }
}

h3 {
    text-align: left;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0.5em;
}

h4 {
    text-align: left;
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 1em;
}

h5 {
    text-align: left;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 1em;
}

h6 {
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 0px;
}

.fs {
    &-08 {
        font-size: 0.8em;
    }

    &-09 {
        font-size: 0.9em;
    }

    &-10 {
        font-size: 1em;
    }

    &-11 {
        font-size: 1.1em;
    }

    &-12 {
        font-size: 1.2em;
    }

    &-13 {
        font-size: 1.3em;
    }

    &-14 {
        font-size: 1.4em;
    }

    &-15 {
        font-size: 1.5em;
    }

    &-16 {
        font-size: 1.6em;
    }

    &-17 {
        font-size: 1.7em;
    }

    &-18 {
        font-size: 1.8em;
    }

    &-19 {
        font-size: 1.9em;
    }

    &-20 {
        font-size: 2em;
    }

    &-25 {
        font-size: 2.5em;
    }

    &-30 {
        font-size: 3em;
    }

    &-35 {
        font-size: 3.5em;
    }

    &-40 {
        font-size: 4em;
    }
}

.fw {
    &-3 {
        font-weight: 300;
    }

    &-4 {
        font-weight: 400;
    }

    &-5 {
        font-weight: 500;
    }

    &-6 {
        font-weight: 600;
    }

    &-7 {
        font-weight: 700;
    }

    &-8 {
        font-weight: 800;
    }

    &-9 {
        font-weight: 900;
    }
}

.color-1 {
    color: $color-1;
}

.color-2 {
    color: $color-2;
}

.color-3 {
    color: $color-3;
}

.color-5 {
    color: $color-5;
}

.gray-2 {
    color: $gray-2;
}

.gray-3 {
    color: $gray-3;
}

.red {
    color: $red;
}

.blue {
    color: $blue;
}

.bg-1 {
    background-color: $gray-1;
}

.bg-2 {
    background-color: $color-3;
}

.flex-1 {
    flex: 1;
}

.box {
    @include box();
}

.w-xs-100 {
    @media (max-width: 575px) {
        width: 100% !important;
    }
}

.link {
    color: $color-1;
    border-bottom: 1px dotted currentColor;
    transition: $transition;

    &:hover,
    &:focus {
        color: $color-2;
    }
}

.dropdown {
    &-toggle {
        color: $gray-2;
        transition: $transition;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
            color: $color-5 !important;
        }
    }

    &-item {
        color: $gray-5;
        transition: $transition;

        &:hover,
        &:focus {
            background-color: $gray-1;
        }

        &:active,
        &.active {
            background-color: $color-4;
            color: $color-5;
        }
    }

    &-menu {
        border-radius: $radius-sm;
        @include box();
        padding: .75em 0;
        overflow: hidden;
        @include safariFix();
    }
}

.icon-mini {
    background-color: $color-3;
    width: 7em;
    height: 7em;
    border-radius: 50%;
    color: #fff;
    @include flex-center();

    svg {
        font-size: 5em;
    }
}

.swiper {
    position: unset;

    &-button {
        top: 50%;
        margin: 0px;
        background-color: #fff;
        width: 3em;
        height: 3em;
        @include flex-center();
        border-radius: 50%;
        box-shadow: 0px 2px 6px rgba($gray-4, .25);

        @media (max-width: 991px) {
            display: none;
        }

        &::after {
            color: $color-2;
            font-size: 1.5em;
        }

        &-next {
            @extend .swiper-button;
            right: 0px;
            transform: translateY(-50%) translateX(50%);
        }

        &-prev {
            @extend .swiper-button;
            left: 0px;
            transform: translateY(-50%) translateX(-50%);
        }
    }

    &-pagination {
        bottom: .5em;

        &-bullet {
            opacity: 1;
            background-color: $gray-2;

            &-active {
                background-color: $color-2;
            }
        }
    }
}


body {
    margin: 0;
    font-family: $main-font, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: $gray-5;
    font-weight: 400;
    overflow-x: hidden;

    @media (max-width: 1399px) {
        font-size: 15px;
    }

    @media (max-width: 1199px) {
        font-size: 14px;
    }

    @media (max-width: 991px) {
        padding-bottom: var(--m-footer);
    }

    // @media (max-width: 575px){
    //     font-size: 12px;
    // }
}

header {
    height: var(--h-height);
    background-color: #fff;
    position: relative;
    z-index: 1100;

    &.h-search {
        nav.left {
            flex: 1;

            .form-search-1 {
                flex: 1;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        nav.left {
            @include flex(row, flex-start, center, nowrap);

            .logo {
                display: block;
                width: 150px;
                height: 30px;
                object-fit: scale-down;
                line-height: 30px;
                margin-right: 1em;

                @media (max-width: 1399px) {
                    width: 125px;
                }

                @media (max-width: 1199px) {
                    width: 150px;
                }

                @media (max-width: 991px) {
                    width: 200px;
                    height: 40px;
                }

                @media (max-width: 575px) {
                    width: 160px;
                    height: 35px;
                }
            }

            form {
                display: flex;
                width: 250px;

                @media (max-width: 1399px) {
                    width: 225px;
                }
            }

            ul {
                @include list();
                @include flex-center();
                margin-left: 2em;
                position: relative;

                &>li {
                    &:not(:first-child) {
                        margin-left: 1.25em;
                    }

                    position: relative;

                    &>a,
                    &>button {
                        font-size: 1em;
                        color: $gray-3;
                        transition: $transition;
                        position: relative;


                        &:hover,
                        &:focus,
                        &:active,
                        &.active {
                            color: $gray-5;
                        }
                    }
                }
            }

        }

        nav.right {
            ul {
                @include list();
                @include flex-center();

                &>li {
                    &:not(:first-child) {
                        margin-left: 1em;
                    }

                    position: relative;

                    .unread-header-conversations-count {
                        position: absolute;
                        top: .1em;
                        right: .1em;
                        transform: translate(50%, -50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
                        width: .6em;
                        height: .6em;
                        border-radius: 50%;
                        background-color: $color-5;
                        color: $color-4;
                    }

                    &>button,
                    &>a {
                        display: flex;
                        font-size: 1.5em;
                        color: $gray-3;
                        transition: $transition;
                        align-items: center;


                        span {
                            font-size: .75em;
                            margin-right: .3em;
                        }

                        @media (max-width: 1399px) {
                            font-size: 1.5em;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $gray-5;
                        }
                    }
                }
            }
        }
    }
}

.lang-switcher {
    position: relative;
    width: 5em;

    &>button {
        background-color: $color-5;
        color: #fff;
        width: 100%;
        font-size: 1.25em;
        padding: .3em .6em;
        border-radius: $radius-sm;
        @include flex(row, space-between, center, nowrap);

        svg {
            margin-left: .5em;
        }

        transition: border-radius .1s linear;
    }

    ul {
        @include list();
        background-color: $gray-1;
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0px;
        width: 100%;
        height: auto;
        @include flex(column, flex-start, stretch, nowrap);
        border-radius: 0 0 $radius-sm $radius-sm;

        li {
            width: 100%;
            margin: 0px !important;

            button {
                @include flex(row, space-between, center, nowrap);
                padding: .3em .6em;
                font-size: 1.25em !important;
                width: 100%;

                img {
                    width: .75em;
                    height: .75em;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }

    &.opened {
        &>button {
            border-radius: $radius-sm $radius-sm 0 0;
        }
    }
}

main {
    min-height: calc(100vh - var(--h-height) - var(--f-height));
}


@media (max-width: 767px) {
    .slider-6 {
        .swiper-slide {
            width: 220px;
        }
    }
}

.labor {
    border-radius: $radius;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        height: 290px;
        object-fit: cover;
        position: relative;
        z-index: 1;
    }

    figcaption {
        position: absolute;
        z-index: 2;
        bottom: 1em;
        left: 1em;
        width: calc(100% - 2em);
    }
}

.slider-main {
    position: relative;

    .swiper-slide {
        position: relative;
        height: 600px;

        @media (max-width: 1399px) {
            height: 550px;
        }

        @media (max-width: 1199px) {
            height: 500px;
        }

        @media (max-width: 991px) {
            background-position: 75% 50%;
        }

        @media (max-width: 767px) {
            padding-bottom: 2em;
        }

        @media (max-width: 575px) {
            height: 100vw;
        }

        .bg {
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            object-fit: cover;

            @media (max-width: 991px) {
                object-position: right;
                filter: brightness(.75) blur(2px);
            }

            @media (max-width: 767px) {
                object-position: 90%;
            }

            @media (max-width: 575px) {
                object-position: 80%;
            }
        }

        section {
            position: relative;
            z-index: 2;
            height: 100%;

            .container {
                height: 100%;

                h1 {
                    color: #fff;
                }
            }
        }
    }

    .swiper-button-disabled {
        opacity: 0 !important;
    }

    .swiper-button-next {
        top: unset;
        bottom: 3em;
        right: unset;
        left: calc(50% + var(--w)/2);
        transform: translateX(-100%);

        @media (max-width: 991px) {
            display: flex;
        }

        @media (max-width: 767px) {
            top: 3em;
            bottom: unset;
        }
    }

    .swiper-button-prev {
        top: unset;
        bottom: 3em;
        left: calc(50% + var(--w)/2);
        right: unset;
        transform: translateX(-100%);

        @media (max-width: 991px) {
            display: flex;
        }

        @media (max-width: 767px) {
            top: 3em;
            bottom: unset;
        }
    }
}

.sec-1 {
    border-radius: $radius;
    background-color: $color-4;

    @media (max-width: 575px) {
        border-radius: $radius*0.5;
    }

    form {
        position: relative;
        @include flex(row, flex-start, stretch, nowrap);

        @media (max-width: 991px) {
            height: 3em;
            border-radius: 2em;
            overflow: hidden;
            @include safariFix();
        }

        input {
            flex: 1;
        }

        button {
            padding: 1.2em 5em;

            @media (max-width: 991px) {
                position: absolute;
                z-index: 10;
                right: 0em;
                top: 0em;
                height: 3em;
                width: 3em;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 1.75em;
                }
            }
        }
    }

    .slider-auto {
        .swiper-slide {
            width: fit-content;
        }
    }

    .category-mini {
        text-align: center;
        @include flex-center();
        flex-direction: column;
        position: relative;

        img {
            width: 3.5em;
            height: 3.5em;
            border-radius: 50%;
        }

        figcaption {
            margin-top: .25em;
            font-size: .8em;
            font-weight: 500;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px 30px;

        @media (max-width: 991px) {
            gap: 1em 1em;
            font-size: 12px;
        }

        @media (max-width: 767px) {
            font-size: 10px;
        }

        @media (max-width: 575px) {
            font-size: 8px;
        }

        div {
            grid-row: span 1;
            grid-column: span 2;

            &:nth-child(5n+4),
            &:nth-child(5n+5) {
                grid-column: span 3;
            }

            .category-card {
                display: block;
                filter: drop-shadow(0px 0px .5em rgba($color: $gray-3, $alpha: .25));
                transition: $transition;

                &:hover {
                    filter: drop-shadow(0px 0px .5em rgba($color: $color-5, $alpha: .5));
                }
            }

            figure {
                background-color: #fff;
                border-radius: $radius;
                overflow: hidden;
                @include safariFix();
                position: relative;
                height: 240px;

                @media (max-width: 1199px) {
                    height: 220px;
                }

                @media (max-width: 991px) {
                    height: 180px;
                }

                @media (max-width: 767px) {
                    height: 150px;
                }

                @media (max-width: 575px) {
                    height: 28vw;
                    border-radius: $radius*0.5;
                }

                img {
                    @include absBG();
                    object-fit: contain;
                    object-position: right bottom;

                    @media (max-width: 767px) {
                        opacity: .75;
                    }
                }

                figcaption {
                    position: relative;
                    z-index: 2;
                    padding: 1.5em;
                    width: 100%;
                    height: 100%;

                    @media (max-width: 575px) {
                        padding: 1em;
                    }
                }
            }
        }
    }
}


.sec-2 {
    .box {
        background-color: $gray-1;
        border-radius: $radius;
        padding: 3em;
        height: 100%;

        @media (max-width: 1199px) {
            padding: 2em;
        }

        @media (max-width: 991px) {
            background-color: transparent;
            border-radius: 0px;
            padding: 0px;
            height: fit-content;
        }

        form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 991px) {
                position: absolute;
                top: 1em;
                left: 5%;
                width: 90%;
                height: fit-content;
                z-index: 10;
                filter: drop-shadow(0px 3px 6px rgba($gray-3, .4));

                div,
                button {
                    display: none;
                }
            }
        }
    }

    .svg-animation {
        border-radius: $radius;
        width: 100%;
        height: 100%;
        transform: translate3d(0px, 0px, 0px);
        position: relative;
        z-index: 1;

        @media (max-width: 767px) {
            height: 350px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.sec-3 {
    background-color: $color-3;

    @media (max-width: 991px) {
        padding: 3em 0;
        border-radius: $radius;
    }

    @media (max-width: 575px) {
        padding: 2em 0;
    }

    .container {
        position: relative;
        z-index: 1;
    }
}

.slider-orders {
    height: 450px;
    position: relative;

    &::before,
    &::after {
        content: '';
        z-index: 10;
        position: absolute;
        left: 0px;
        width: 100%;
        height: 15%;

        @media (max-width: 991px) {
            width: 10%;
            height: 100%;
            left: unset;
            top: 0px;
        }
    }

    &::before {
        top: 0px;
        background-image: linear-gradient(to bottom, rgba($color-3, .4), transparent);

        @media (max-width: 991px) {
            left: 0px;
            background-image: linear-gradient(to right, $color-3, rgba($color-3, .4), transparent);
        }
    }

    &::after {
        bottom: 0px;
        background-image: linear-gradient(to top, rgba($color-3, .4), transparent);

        @media (max-width: 991px) {
            right: 0px;
            background-image: linear-gradient(to left, $color-3, rgba($color-3, .4), transparent);
        }
    }

    @media (max-width: 991px) {
        height: fit-content;
        margin-top: 1em;
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }

    .swiper-slide {
        height: fit-content;
        transition-timing-function: linear !important;

        @media (max-width: 575px) {
            width: 160px;
        }
    }
}

.recent-orders {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.25em;

    &-item {
        width: 25%;
        background-color: #fff;
        border-radius: $radius;
        height: 230px;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        @media (min-width: 992px) {
            &:nth-child(even) {
                transform: translateY(50%);
            }
        }

        @media (max-width: 991px) {
            width: 100%;
        }

        .icon {
            background-color: $color-2;
            color: #fff;
            font-size: 1.5em;
            height: 2em;
            width: 2em;
            @include flex-center();
            border-radius: 50%;
            margin-bottom: .5em;
        }
    }
}

.sec-4 {
    .thumbslides {
        .swiper-slide {
            display: flex;

            p {
                flex: 1;
                padding: .5em 1em;
                color: $gray-3;
                font-size: 1.25em;

                @media (max-width: 1199px) {
                    font-size: 1em;
                }
            }

            .indicator {
                width: 3px;
                border-radius: 3px;
                background-color: $color-4;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 0%;
                    border-radius: 3px;
                    background-color: $color-5;
                }
            }

            &-thumb-active {
                p {
                    color: $gray-5;
                }

                .indicator {
                    &::before {
                        height: 100%;
                        transition: 3s linear;
                    }
                }
            }
        }
    }

    .thumbs-main {
        img {
            width: 100%;
            border-radius: $radius*2;
            height: 500px;
            object-fit: cover;

            @media (max-width: 1199px) {
                height: 450px;
            }
        }
    }

    .help-slider {
        height: 700px;
        background-color: #fff;
        box-shadow: 0 0 1em rgba($gray-3, .15);
        border-radius: $radius*2;
        @include safariFix();

        @media (max-width: 767px) {
            height: 515px;
        }

        @media (max-width: 575px) {
            height: 125vw;
            border-radius: $radius;
        }

        .swiper-slide {
            overflow: hidden;
            padding: 2em;
            @include flex-center();
            flex-direction: column;
            opacity: 0 !important;
            transition: $transition;

            &-visible {
                opacity: 1 !important;
            }

            @media (max-width: 575px) {
                padding: 1em;

                h4 {
                    font-size: 1em;
                }
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: $radius*2;
                flex: 1;
            }
        }
    }
}

.sec-5 {
    @media (max-width: 991px) {
        border-bottom: 1px solid $gray-1;
    }

    .container {
        background-color: $gray-1;
        border-radius: $radius;
        padding: 2em 4em;
        position: relative;

        @media (max-width: 991px) {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            text-align: center;
        }
    }

    .apps-links {
        justify-content: flex-start;
        margin-top: 2em;

        @media (max-width: 991px) {
            justify-content: center;
            margin-bottom: 2em;
        }
    }

    .bg {
        position: absolute;
        right: 11%;
        bottom: 0px;
        max-height: 120%;

        @media (max-width: 1199px) {
            max-height: 110%;
        }

        @media (max-width: 991px) {
            position: unset;
            right: unset;
            bottom: unset;
            max-height: auto;
            max-width: 100%;
        }
    }
}

.sec-6 {
    .box {
        background-color: $color-3;
        padding: 1em 1.5em;
        border-radius: $radius;
    }
}

.services-grid {
    margin-top: 2em;

    a {
        display: flex;
        align-items: center;
        font-size: 1em;

        svg {
            font-size: 2em;
            margin-right: .5em;
        }

        img {
            width: 4em;
            margin-right: .5em;

            @media (max-width: 575px) {
                width: 2em;
            }
        }
    }
}

.nav-2 {
    ul {
        @include list();

        a {
            @include flex(row, flex-start, center, nowrap);
            color: $gray-5;
            transition: $transition;

            &:hover,
            &:focus {
                color: $color-5;
            }

            &:active,
            &.active {
                color: $color-2;
            }

            svg {
                font-size: 1.3em;
                margin-right: .5em;
            }
        }
    }
}

.sec-7 {
    ul {
        @include list();

        &.categories {
            li {
                &:not(:first-child) {
                    margin-top: 1em;
                }
            }

            a,
            button {
                color: $gray-5;
                font-weight: 500;
                transition: $transition;
                display: block;
                width: 100%;
                background-color: $gray-1;
                text-align: left;
                padding: .5em .5em .5em .75em;
                border-radius: $radius;
                @include flex(row, space-between, center, nowrap);

                @media (max-width: 991px) {
                    font-size: 1.25em;
                    padding: 1em 1em;
                }

                @media (max-width: 575px) {
                    font-size: 1em;
                }

                &:hover,
                &:focus,
                &:active,
                &.active {
                    color: $color-1;
                }

                span {
                    flex: 1;
                    line-height: 100%;
                }

                svg {
                    opacity: 0;

                    @media (max-width: 991px) {
                        opacity: 1;
                    }
                }

                &.active {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }

        &.subcategories {

            a,
            button {
                color: $gray-5;
                font-weight: 400;
                transition: $transition;

                &:hover,
                &:focus,
                &:active,
                &.active {
                    color: $color-5;
                }
            }

            @for $i from 1 through 6 {
                li:nth-child(#{$i}) {
                    a {
                        font-size: 1.2em;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .order {
        border: 1px solid $gray-1;

        .info-1 {
            @include list();

            li {
                display: inline;

                &:not(:first-child) {
                    margin-left: 1em;

                    &::before {
                        content: '•';
                        margin-right: 1em;
                    }
                }
            }
        }

        .info-2 {
            @include list();
            @include flex(row, flex-start, center, nowrap);

            li:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    .orders-list {
        @include list();

        &>li {
            &:not(:first-child) {
                margin-top: 2em;
            }
        }
    }
}

.service-offer {
    @include box();
    border-radius: $radius-sm;
    padding: 1em;
    display: flex;

    gap: 1em 1em;
    margin-bottom: 2em;

    @media (max-width: 767px) {
        grid-template-columns: 3fr 7fr;
        margin-bottom: 1.5em;
    }

    .photo {
        width: 200px;

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
            border-radius: $radius-sm;
            margin-bottom: 1em;


        }

        .rating {
            gap: 10px;

            @media (max-width: 767px) {
                @include flex(row, flex-start, center, nowrap);
            }
        }
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5em;
        width: 100%;

        p {
            @include clamp(2, 1.2em);

            @media (max-width: 575px) {
                display: none;
            }
        }



        .btns {
            position: absolute;
            bottom: 0;
            display: flex;
            @include flex(row, flex-start, center, nowrap);
            font-size: .9em;
            margin-top: 1em;

            button {
                padding: 1em;
            }
        }
    }


}

.view-offer {
    .topic {
        @include box();
        border-radius: $radius-sm;
        padding: 1em;
        margin-bottom: 2em;
        position: relative;

        .drop {
            position: absolute;
            top: 1em;
            right: 1em;

            .dropdown-menu {
                border-radius: 1em !important;
            }
        }


        .head {
            display: flex;
            margin-bottom: 1em;

            .photo {
                margin-right: 1em;

                img {
                    width: 100%;
                    height: 160px;
                    object-fit: cover;
                    border-radius: $radius-sm;

                    @media (max-width: 1399px) {
                        height: 100px;
                    }

                    @media (max-width: 1199px) {
                        height: 120px;
                    }

                    @media (max-width: 575px) {
                        height: 100vw;
                    }
                }
            }

            .title {
                h2 {
                    font-size: 2em;
                    margin-bottom: .4em !important;
                }

                p {
                    margin-bottom: 1em;
                }

                .btns {
                    @include flex(row, flex-start, center, nowrap);
                    font-size: .9em;

                    button {
                        padding: 1em;
                    }
                }


            }


        }

        .text {
            margin-bottom: 1em;
            @include clamp(2, 1.2em);
        }

        .options {
            margin-bottom: 1em;

            li {
                margin-bottom: .6em;
            }
        }


    }

    .works {
        @include box();
        border-radius: $radius-sm;
        padding: 1em;
        margin-bottom: 2em;

        .imgs {
            min-width: 0;
            position: relative;


            .slider {
                max-width: 100%;
                width: 100%;

                .swiper-slide {
                    width: fit-content;
                }

                .swiper-button-prev,
                .swiper-button-next {
                    font-size: .75em;
                }
            }

            img {
                width: 160px;
                height: 160px;
                object-fit: cover;
                border-radius: $radius-sm;
                margin-bottom: .4em;
            }

            .title {
                color: $gray-3;
                font-size: .9em;
                margin-bottom: .2em;
            }
        }
    }

    .services {
        @include box();
        border-radius: $radius-sm;
        padding: 1em;
        margin-bottom: 2em;

        .price-list {

            ul {
                @include list();

                li {

                    .title {
                        color: $gray-3;
                        font-size: .9em;
                        margin-bottom: 0.8em;
                    }

                    padding: .25em .5em;
                    font-weight: 500;

                    .text {
                        margin-bottom: 1em;
                        @include clamp(2, 1.2em);
                    }

                    &:not(:first-child) {
                        @include flex(row, space-between, center, nowrap);
                        margin-top: .25em;
                    }
                }
            }
        }
    }

}

.filter {
    background-color: rgba($color-1, .1);
    padding: 1em;
    border-radius: $radius-sm;
    font-size: .9em;

    fieldset {
        margin-bottom: 1em;

        a,
        button {
            color: $color-1;
            transition: $transition;

            &:hover,
            &:focus,
            &:active {
                color: $color-2;
            }
        }

        legend {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: .5em;
        }

        input:not([type="radio"]):not([type="checkbox"]) {
            padding: .75em 1em;
        }

        ul {
            @include list();

            li:not(:first-child) {
                margin-top: .5em;
            }
        }
    }
}

.apps-links {
    @include flex(row, flex-end, center, nowrap);
    @include list();

    @media (max-width: 1199px) {
        justify-content: flex-start;
    }

    li {
        &:not(:first-child) {
            margin-left: 1em;

            @media (max-width: 575px) {
                margin-left: .25em;
            }
        }

        img {
            max-height: 50px;

            @media (max-width: 575px) {
                max-height: 40px;
            }
        }
    }
}

/* Account */
.account-nav {
    ul {
        @include list();

        li {
            margin-bottom: 1em;


            a {
                font-weight: 600;
                color: $gray-3;
                transition: $transition;

                &:hover,
                &:focus,
                &.active {
                    color: $gray-5;
                }
            }
        }
    }
}


/* Chat */
.chat {
    position: relative;
    // background: var(--glass);
    // box-shadow: var(--shadow);
    border-radius: 10px;
    overflow: hidden;

    &-window {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;
        padding: 1em 1.5em;
        max-height: calc(100vh - var(--h-height) - 10em);





        @media (max-width: $bp-lg) {
            padding: 1em 1.5em;
            max-height: calc(100vh - var(--h-height) - var(--m-footer) - 10em);
        }

        @media (max-width: $bp-md) {
            padding: 1em;
            max-height: calc(100vh - var(--h-height) - var(--m-footer) - 10em);
        }

        @media (max-width: $bp-sm) {
            padding: 1em 0.5em;
            max-height: calc(100vh - var(--h-height) - var(--m-footer) - 8em);
        }

        &-message {
            @include flex(row, flex-start, flex-start);
            margin-bottom: 1.5em;

            img {
                width: 3.34em;
                height: 3.34em;
                border-radius: 50%;
                position: relative;
                z-index: 10;
                margin-right: 1em;
            }

            .text {
                flex: 1;
                @include flex(column, flex-start, flex-start, nowrap);
                white-space: pre-wrap;

                .bubble {
                    position: relative;
                    width: fit-content;
                    background: $mess-user;
                    border-radius: $radius;
                    padding: 1em;
                    font-weight: 400;
                    overflow-wrap: anywhere;
                    font-size: 0.9em;
                    text-align: left;

                    &-img {
                        max-height: 228px;
                        max-width: 304px;
                        height: 228px;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 3%;
                        cursor: pointer;
                        margin-left: 0px;
                        margin-right: 0em;
                        margin-top: 1em;
                    }

                    &-admin {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: $mess-admin;
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: left;
                    }

                    &-system {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: $mess-system;
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: center;
                        white-space: normal;


                        &-img {
                            height: auto;
                            width: 150px;
                            color: #eee !important;
                            border-radius: 0%;
                        }
                    }

                    &-ban {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: $mess-ban;
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: left;
                        color: white;
                    }
                }
            }

            &-mine {
                @extend .chat-window-message;

                img {
                    margin-left: 1em;
                    margin-right: 1em;
                }

                .text {
                    align-items: flex-end;
                    white-space: pre-wrap;

                    .bubble {
                        overflow-wrap: anywhere;
                        background: $mess-mine;
                        text-align: right;

                        &-admin {
                            overflow-wrap: anywhere;
                            background: $mess-admin;
                            text-align: right;
                        }

                        &-system {
                            overflow-wrap: anywhere;
                            background: $mess-system;
                            text-align: center;
                            white-space: normal;


                            &-img {
                                height: auto;
                                width: 150px;
                                color: $gray-1 !important;

                                border-radius: 0%;
                            }
                        }

                        &-ban {
                            background: $mess-ban;
                            color: white;
                            overflow-wrap: anywhere;
                            text-align: right;
                        }

                        &-img {
                            margin-left: 0px;
                            margin-right: 0em;
                            margin-top: 1em;
                            cursor: pointer;
                            max-height: 228px;
                            max-width: 304px;
                            height: 228px;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 3%;
                        }
                    }
                }
            }
        }
    }

    &-form {
        @include flex(row, flex-start, stretch, nowrap);
        border-top: 1px solid #9DC4EF4D;

        textarea {
            border: none;
            background-color: transparent;
            resize: none;
        }

        .input-file {
            padding-right: 0;

            &:hover,
            &:active {
                svg {
                    color: $color-5 !important;
                }
            }
        }

        button {
            background: $color-2;
            margin-left: 1.25em;
            padding: .9em 1.4em;
            font-size: .9em;
            color: white;
            transition: $transition;
            transition-property: background-color, color;
            border-radius: 8px;

            &:hover,
            &:active {
                background: $color-5;
            }
        }
    }
}


.dialog-preview {
    @include flex(row, flex-start, center, nowrap);
    padding: 0.75em 1.3em;
    position: relative;
    width: 100%;

    &.active {
        background-color: $gray-1;
    }

    img {
        @include rounedImg(3em);
        margin-right: 0.5em;
        position: relative;
        z-index: 1;
    }

    .indicator {
        position: absolute;
        bottom: 0.5em;
        left: 3.5em;
        width: 0.67em;
        height: 0.67em;
        border-radius: 50%;
        z-index: 10;

        &.green {
            background-color: var(--green);
        }
    }

    .count {


        span {
            display: flex;
            background-color: #66c6e4 !important;
            align-items: center;
            justify-content: center;
            color: #0d59ab;
            font-size: 16px;
            font-weight: 400;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;

            @media (max-width: 767px) {
                font-size: 13px;
            }
        }
    }

    .text {
        flex: 1;
        width: 80%;

        h6 {
            @include clamp(1, 1.2em, true);
        }

        time {
            font-weight: 300;
            color: var(--gray);
            font-size: 0.7em;
        }

        p {
            // @include clamp(1, 1.2em, true);
            display: block;
            font-weight: 300;
            color: var(--gray);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            margin-top: .5em;
        }
    }
}

.sec-messages {
    position: relative;
    @include flex(row, flex-start, stretch, wrap);
    background-color: var(--body-bg);
    border: 1px solid $gray-1;
    box-shadow: 0px 2px 6px $gray-1;
    border-radius: $radius;
    overflow: hidden;
    @include safariFix();
    max-height: calc(100vh - var(--h-height) - 2em);

    @media (max-width: $bp-lg) {
        max-height: calc(100vh - var(--h-height) - var(--m-footer));
    }

    &-list {
        width: 30%;
        border-right: 1px solid $gray-1;

        @media (max-width: $bp-xxl) {
            width: 35%;
        }

        @media (max-width: $bp-xl) {
            width: 100%;
            border-right: none;
        }


        ul {
            height: calc(100vh - var(--h-height) - 10em);
            @include list();
            overflow-y: auto;

            .div {
                height: calc(100vh - var(--h-height) - 11em);
            }

            @media (max-width: $bp-lg) {
                height: calc(100vh - var(--h-height) - var(--m-footer) - 14em);
            }

            @media (max-width: $bp-sm) {
                height: calc(100vh - var(--h-height) - var(--m-footer) - 11em);
            }
        }


    }

    &-chat {
        box-shadow: 0px 2px 6px $gray-1;
        width: 70%;
        position: relative;

        @media (max-width: $bp-xxl) {
            width: 65%;
        }

        @media (max-width: $bp-xl) {
            width: 100%;
        }

        &-top {
            @include flex(row, flex-start, center, nowrap);
            padding: 0.75em 1.5em;
            border-bottom: 1px solid var(--extrapale-blue);
        }

        .chat {
            border: none;


        }
    }
}

::-webkit-scrollbar {
    width: 7px;
    background-color: $gray-1;
    border-radius: 6px;

}

::-webkit-scrollbar-thumb {
    background-color: $gray-2;
    border-radius: 6px;


    &:hover {
        background-color: $gray-3;
    }

    &:active {
        background-color: $gray-3;
    }
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
}


.return-title {
    display: none;

    @media (max-width: $bp-lg) {
        @include flex(row, flex-start, center, nowrap);
        margin-bottom: 2em;
    }
}

.return-icon {
    display: flex;
    font-size: 2em;
    color: var(--blue);
}

/* Modal */
.modal {


    &-img {
        max-width: 100%;
        width: auto;
        max-height: 80vh;
        border-radius: 4%;
        // object-fit: contain;
    }

    &-content {
        padding: 0em 1em 2em 1em !important;
        background-color: $gray-1;
        border: none;
        border-radius: $radius;
    }

    &-header {
        position: relative;
        height: 35px;
        padding: 0px;
        background-color: $color-4;
        border-radius: $radius;
        border: none;

        .warning {
            position: absolute;
            right: 2em;
            bottom: 0.25em;

            @media (max-width: $bp-lg) {
                width: 20%;
            }
        }

        .btn-close {
            background: url(imgs/close.svg) no-repeat center;
            background-size: contain;
            background-color: var(--white);
            width: 1.34em;
            height: 1.34em;
            padding: 0px;
            margin: 0px;
            border-radius: 50%;
            overflow: hidden;
            @include safariFix();
            transition: $transition;
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            z-index: 1;

            &:hover,
            &:focus {
                background-color: var(--dark-blue);
            }
        }
    }

    &-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 80vh;
    }

    &-body {
        padding: 1.34em 2.68em;

        @media (max-width: $bp-sm) {
            padding: 1.34em;
        }
    }
}

.sw-mini {
    .swiper-button-prev {
        width: 2.5em;
        height: 2.5em;
        transform: translateY(-50%) translateX(-50%);
    }

    .swiper-button-next {
        width: 2.5em;
        height: 2.5em;
        transform: translateY(-50%) translateX(50%);
    }

    @media (max-width: 991px) {
        padding-bottom: 1em;
    }

    @media (max-width: 767px) {
        .btn-4 {
            width: 100%;
        }
    }
}

.form-about-info {
    background-color: $gray-1;

    @media (max-width: 575px) {
        font-size: .9em;
    }

    fieldset {
        &:not(:last-child) {
            border-bottom: 2px dashed $gray-2;
            padding-bottom: 2em;
            margin-bottom: 2em;
        }

        legend {
            font-size: 1.5em;
            font-weight: 600;
            margin-bottom: 1em;

            &.mini {
                font-size: 1.1em;
            }
        }
    }
}

.servises-list {
    @include list();

    @media (max-width: 575px) {
        font-size: .9em;
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 2px dashed $gray-1;
            margin-bottom: 0.5em;
            padding-bottom: 0.5em;
        }

        button {
            padding: .75em 1em;

            @media (max-width: 575px) {
                font-size: .8em;
                padding: .75em;
            }
        }
    }
}

.tariff {
    text-align: center;

    h3,
    h5 {
        text-align: center;
    }

    hr {
        background-color: $color-5;
        width: 80%;
        margin: 1em auto;
    }
}

.task {
    @include box();
    margin-bottom: 2em;

    &-mini {
        @include flex(row, flex-start, center, nowrap);

        &:hover,
        &:focus,
        &:active {

            svg,
            .text h6 {
                color: $color-5;
            }
        }

        .text {
            flex: 1;

            h6 {
                color: $color-2;
                transition: $transition;
                @include clamp(2, 1.2em);
                margin-bottom: .5em;
            }

            ul {
                @include list();
                font-size: .9em;
                color: $gray-3;
                @include flex(row, flex-start, center, nowrap);

                li {
                    &:not(:first-child) {
                        margin-left: .5em;

                        &::before {
                            content: '⦁';
                            margin-right: .5em;
                        }
                    }
                }
            }
        }

        svg {
            color: $color-2;
            font-size: 1.5em;
            transition: $transition;
        }
    }

    .top {
        @include flex(row, space-between, center, nowrap);

        @media (max-width: 575px) {
            font-size: .9em;
        }
    }

    .info {
        @include list();
        @include flex(row, flex-start, center, nowrap);
        margin-bottom: 1em;
        color: $gray-3;
        font-size: .9em;

        @media (max-width: 575px) {
            font-size: .8em;
        }

        li {
            &:not(:first-child) {
                margin-left: 1em;

                &::before {
                    content: '⦁';
                    margin-right: 1em;
                }
            }
        }
    }

    .text {
        @include clamp(2, 1.2em);
        margin-bottom: 1em
    }

    .work {
        @include list();
        @include flex(row, flex-start, center, nowrap);

        @media (max-width: 575px) {
            display: block;
        }

        li {
            @include flex(row, flex-start, center, nowrap);
            margin-bottom: .5em;
        }
    }

    .user {
        @include flex(row, flex-start, center, nowrap);
        margin-bottom: 1em;

        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;

            @media (max-width: 767px) {
                width: 50px;
                height: 50px;
            }

            @media (max-width: 575px) {
                width: 30px;
                height: 30px;
            }
        }
    }

    .btns {
        @include flex(row, space-between, center, nowrap);

        .btn-1 {
            padding: 1em 1.5em;

            @media (max-width: 575px) {
                padding: .75em 1em;
            }
        }

        .red {
            font-size: 2em;

            @media (max-width: 575px) {
                font-size: 1.5em;
            }
        }
    }
}

.task-filter {
    @include box();

    // font-size: .9em;
    fieldset {
        &:not(:last-child) {
            padding-bottom: 1em;
            border-bottom: 1px solid rgba($color: $gray-2, $alpha: .5);
            margin-bottom: 1em;
        }

        legend {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: .5em;
        }

        ul {
            @include list();

            li:not(:first-child) {
                margin-top: .5em;
            }

            &.horizontal {
                @include flex(row, flex-start, flex-start, wrap);

                li {
                    margin-top: 0px;
                    margin-bottom: .75em;

                    &:not(:last-child) {
                        margin-right: 1em;
                    }
                }
            }
        }

        input:not([type="checkbox"]) {
            border: 1px solid $gray-2;
            padding: .9em 1em;
        }
    }

    .pill {
        @include flex(row, flex-start, center, nowrap);
        background-color: $gray-1;
        border-radius: $radius-sm;
        font-size: .9em;
        padding: 0 0 0 .5em;
        margin-top: 0px;

        button {
            display: flex;
            margin-left: .25em;
            color: $red;
            font-size: 1.5em;
        }
    }
}

.offers {
    ul {
        @include list();
        @include flex(row, flex-start, center, nowrap);
        margin-bottom: 2em;

        li {
            &:not(:first-child) {
                margin-left: 1em;

                @media (max-width: 575px) {
                    margin-left: .5em;
                }
            }

            button,
            a {
                padding: 1em 1.5em;

                @media (max-width: 575px) {
                    padding: .75em .5em;
                    line-height: 100%;
                    font-size: .9em;
                }
            }
        }
    }
}

.offer-page-card {
    position: relative;

    .btn-none {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 100;
    }

    ul {
        @include list();

        &.horizontal {
            @include flex(row, flex-start, center, nowrap);

            li {
                color: $gray-3;
                font-size: .9em;

                @media (max-width: 575px) {
                    font-size: .8em;
                }

                &.pill {
                    background-color: $color-4;
                    border-radius: $radius-sm;
                    padding: 0 .5em;
                }

                &:not(:first-child) {
                    margin-left: .5em;

                    &:before {
                        content: '⦁';
                        margin-right: .5em;
                    }
                }
            }
        }

        &.vertical {
            li {
                @include flex(row, flex-start, center, nowrap);

                .icon {
                    width: 2em;
                    height: 2em;
                    border-radius: 50%;
                    object-fit: scale-down;
                    background-color: $color-4;
                    @include flex(row, center, center, nowrap);

                    svg {
                        font-size: 1.2em;
                        color: $color-5;
                    }
                }

                &:not(:first-child) {
                    margin-top: .75em;
                }
            }
        }
    }

    .user {
        @include flex(row, flex-start, center, nowrap);

        img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;

            @media (max-width: 575px) {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.list-bordered {
    @include list();

    &>li {
        border-top: 1px solid $gray-1;
        padding: .75em 0;

        &:last-child {
            border-bottom: 1px solid $gray-1;
        }
    }
}

.respond {
    .photo {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 1em;
        background-color: $color-4;
        box-shadow: 0px 2px 6px rgba($color: $gray-2, $alpha: .5);

        @media (max-width: 767px) {
            width: 70px;
            height: 70px;
        }

        @media (max-width: 575px) {
            width: 45px;
            height: 45px;
            margin-right: .5em;
        }
    }

    .box {
        background-color: $color-4;

        @media (max-width: 767px) {
            padding: 1.5em;
        }

        @media (max-width: 575px) {
            padding: 1em;
        }

        hr {
            background-color: #fff;
            height: 2px;
        }
    }
}

/* Account END */


footer {
    height: var(--f-height);
    background-color: $gray-5;
    color: $gray-2;
    font-size: .9em;

    a,
    button {
        color: $gray-2;
        transition: $transition;

        &:hover {
            color: $gray-1;
        }
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        .menu {
            display: flex;

            @media (max-width: 767px) {
                display: block;
            }

            ul {
                @include list();

                &:not(:first-child) {
                    margin-left: 3em;

                    @media (max-width: 767px) {
                        margin-left: 0;
                        margin-top: .75em;
                    }
                }

                li:not(:first-child) {
                    margin-top: .75em;
                }
            }
        }

        hr {
            background-color: $gray-2;
            height: 1px;
            width: 100%;
            margin: 1.5em 0px;
            border: none;
            opacity: 1;
        }

        .bottom {
            display: flex;
            align-items: center;

            &>*:not(:first-child) {
                margin-left: 1.5em;
            }

            @media (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;

                &>*:not(:first-child) {
                    margin-top: 1em;
                    margin-left: 0em;
                }
            }
        }
    }

    &.mobile {
        position: fixed;
        z-index: 1100;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: var(--m-footer);
        background-color: #fff;
        box-shadow: 0 3px 12px rgba(13, 35, 67, 0.2);

        nav {
            ul {
                @include flex-center();

                li {
                    width: 25%;

                    a,
                    button {
                        width: 100%;
                        @include flex-center();
                        flex-direction: column;
                        color: $gray-3;
                        position: relative;

                        .unread-header-conversations-count {
                            margin-left: 1em;
                            position: absolute;
                            top: .1em;
                            right: auto;
                            transform: translate(50%, -50%);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 16px;
                            font-weight: 700;
                            width: .6em;
                            height: .6em;
                            border-radius: 50%;
                            background-color: $color-5;
                            color: $color-4;
                        }

                        &.active {
                            color: $color-5;
                        }

                        svg {
                            font-size: 1.75em;
                        }

                        span {
                            font-size: .8em;
                            margin-top: .35em;
                        }
                    }
                }
            }
        }
    }
}

nav.mobile-menu {
    ul {
        @include list();

        li {
            &:not(:first-child) {
                margin-top: .75em;
            }

            a,
            button {
                font-size: 1.2em;
                font-weight: 500;
            }
        }
    }
}

.offcanvas {
    &.offcanvas-top {
        height: fit-content;

        .offcanvas-body {
            padding: var(--h-height) 0 2em 0;

            .form-search-1 {
                input {
                    height: 4em;
                }

                button {
                    width: 4em;

                    svg {
                        font-size: 1.75em;
                    }
                }
            }
        }
    }

    &-end {
        @media (max-width:575px) {
            width: 100% !important;
        }

        &.show {
            padding-top: var(--h-height);
            padding-bottom: var(--m-footer);

            .close {
                position: absolute;
                top: calc(var(--h-height) + 1em);
                right: 2em;
                font-size: 1.5em;
                z-index: 10;
            }
        }
    }

    &-bottom {
        &.show {
            min-height: fit-content;
            height: fit-content;
            padding-bottom: var(--m-footer);

            .close {
                position: absolute;
                top: 1em;
                right: 1em;
                font-size: 1.5em;
                z-index: 10;
            }

            hr {
                background-color: $color-5;
                opacity: 1;
                height: 1px;
                width: 100%;
                border: none;
                margin: 1em 0;
            }
        }
    }
}

.modal {
    z-index: 3000;

    &-backdrop {
        z-index: 2000;
    }

    &-content {
        @include box();
        background-color: $gray-1;
    }

    &-body {
        padding: 0px;
    }

    .close {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 10;
        display: flex;
        font-size: 1.5em;
        color: $gray-3;
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
            color: $red;
        }
    }
}

.create-order-form {
    ul {
        @include list();
        // @include flex(row, flex-start, stretch, wrap);
    }
}

.budget {
    position: relative;
    width: 100%;

    &-btn {
        width: 100%;
        position: relative;
        z-index: 1;
        @include flex(row, flex-start, center, nowrap);
        padding: 1em;
        text-align: center;
        transition: .3s ease;
        font-size: 1em;
        font-weight: 500;
        border-radius: $radius;
        background-color: #fff;

        @media (max-width: 575px) {
            padding: .8em 1em;
            border-radius: $radius-sm;
        }
    }

    &-inner {
        z-index: 2;
        position: absolute;
        top: calc(100% + .3em);
        left: 0px;
        width: 250px;
        background-color: #fff;
        border-radius: $radius;
        padding: 1em;

        input {
            border: 1px solid $gray-2;
        }

        ul {
            display: block;
            max-height: 100px;
            overflow: auto;

            li {
                &:not(:last-child) {
                    margin-bottom: .5em;
                }
            }
        }
    }
}

.limitation {
    position: relative;
    width: 100%;

    &-btn {
        position: relative;
        width: 100%;
        z-index: 1;
        @include flex(row, flex-start, center, nowrap);
        padding: 1em;
        text-align: center;
        transition: .3s ease;
        font-size: 1em;
        font-weight: 500;
        border-radius: $radius;
        background-color: #fff;

        @media (max-width: 575px) {
            padding: .8em 1em;
            border-radius: $radius-sm;
        }
    }

    &-inner {
        z-index: 2;
        position: absolute;
        top: calc(100% + .3em);
        left: 0px;
        width: fit-content;
        background-color: #fff;
        border-radius: $radius;
        padding: 1em;

        input {
            border: 1px solid $gray-2;
        }

        ul {
            display: block;
            max-height: 100px;
            overflow: auto;

            li {
                &:not(:last-child) {
                    margin-bottom: .5em;
                }
            }
        }
    }
}


.notifications-list {
    width: 300px;
    max-height: 450px;
    overflow-y: auto;
    border-radius: $radius-sm;
    background-color: $gray-1;
    box-shadow: 0px 2px 6px $gray-2;
    padding: 1em;

    li {
        background-color: #fff;
        font-size: .9em;
        border-radius: $radius-sm;

        &:not(:last-child) {
            margin-bottom: .5em;
        }

        div {
            @include flex(row, space-between, center, nowrap);

            time {
                font-size: .8em;
                color: $gray-3;
            }
        }

        p {
            margin-top: .5em;
            white-space: normal;
            font-size: .9em;
        }
    }
}

.scroll-to-top {
    z-index: 1042;
    position: fixed;
    bottom: 2em;
    right: 1em;
    backdrop-filter: blur(5px);

    &.mini {
        right: 5em;
    }

    &.maxi {
        right: 24em;
    }

    @media (max-width: $bp-lg) {
        right: 1em;
    }


    background: $gray-1;
    opacity: 0.6;
    font-size: 1.5em;
    color: $color-3;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // box-shadow: var(--shadowMobile);
    transition: color 0.3s ease-in;

    &:hover,
    &:active {
        // background-color: var(--body-bg);
        opacity: 1;
        color: $color-2;
    }
}

.text {
    border: 1px solid #bfbfbf;
    box-shadow: none;

    &:focus {
        border: 1px solid $color-2 !important;
        box-shadow: none !important;
    }
}

.pointer {
    cursor: pointer;
    font-weight: 500;
    display: inline-block;

    &:hover,
    &:active,
    &.active {
        color: $color-2;
        font-weight: 500;
    }


}